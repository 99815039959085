<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent title="Glass Hazards Analysis (GHA)" size="large" />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <div
          class="fusion-column-wrapper"
          style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <span
            class="fusion-imageframe imageframe-none imageframe-1 hover-type-none"
            ><img
              src="@/assets/RiskManagement/Figure-6-28-min.jpg"
              width="1197"
              height="853"
              alt=""
              title="Figure 6-28"
              class="img-responsive wp-image-13069"
              sizes="(max-width: 800px) 100vw, 600px"
          /></span>
          <div class="fusion-clearfix"></div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) regularly performs GHAs on
            facilities using a variety of tools and technical guidance
            documents. The general approach is to evaluate each building located
            within inhabited building distance (IBD) of a potential explosion
            site (PES) using a checklist. A completed checklist is provided to
            our customer for each building evaluated and includes any
            deficiencies in the building’s glass type and installation. All
            required or implemented mitigation actions to bring the building
            into compliance are assembled in an appendix to facilitate work
            orders or integrating into a service contract. A window is
            considered compliant if it withstands the worst-case expected
            overpressure or 3.5 psi, whichever is lower. This approach
            correlates well with DA PAM 385-64 that, for windows within IBD,
            directs that
            <em
              >“An engineering analysis will be made to determine the type of
              blast-resistant glass required to withstand the expected
              overpressure.”</em
            >
          </p>
          <p>
            Windows, skylights, and glass panels within IBD are evaluated to
            determine whether they meet the required criteria to resist expected
            blast pressures. SMS performs a physical assessment of all glass
            within buildings out to IBD blast hazard arcs and determines their
            blast pressure capacity in accordance with Unified Facilities
            Criteria (UFC) 3-340-02,
            <em>Structures to Resist the Effects of Accidental Explosion</em>, 5
            December 2008. Where glass blast pressure capacity did not exceed
            the expected overpressure from an explosive event from nearby PES,
            mitigation actions are recommended. During the evaluation, the team
            focuses on worst-case explosive events in evaluating window and
            glass response. For a given PES, this worst-case event is the
            expected overpressure resulting from the entire NEW involved in an
            event. However, in some cases, window failure is not the major
            hazard of concern. Consideration is also given during our evaluation
            to the fact that although considerable damage is expected at maximum
            net explosive weight (NEW), the NEW involved in any incident is
            nearly always less than the maximum. Consequently, the mitigation
            steps recommended reflect these real-world expectations that
            personnel at less than intraline distance (ILD) may be subject to
            lower pressures and thus consideration of mitigating the glass
            hazard is warranted.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Glass Hazard Analysis",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS regularly performs Glass Hazards Analysis (GHAs) on buildings within the inhabited building distance (IBD) in accordance with DA PAM 385-64."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
